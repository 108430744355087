import React, { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router-dom'

import { Column } from '../../components'
import { useStore, useUnity } from '../../providers'
import { UnityContainer } from '../../unity'

import background from './loading.png'

export type HomeParams = { ring: string; token: string }

const useStyle = createUseStyles({
  image: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
})

export const Home = () => {
  const [loading, changeLoading] = useState(true)
  const classes = useStyle()
  const { search } = useLocation()
  const { user } = useStore()
  const { context } = useUnity()

  const Params = useMemo(() => {
    return search
      .replace('?', '')
      .split('&')
      .reduce((acc, item) => {
        const keyValue = item.split('=')
        return {
          // @ts-ignore
          ...acc,
          [keyValue[0]]: keyValue[1]
        }
      }, {}) as HomeParams
  }, [search])

  const handleOnSendToken = () => {
    const { ring, token } = Params

    if (token) {
      context.send('WebGLDataTransfer', 'SetToken', token || user.token)
      context.send('WebGLDataTransfer', 'SetRingId', ring || '')
    } else {
      history.back()
    }
  }

  const handleOnProgress = (progress: number) => {
    if (progress >= 1) {
      changeLoading(false)
    }
  }

  context.on('getToken', handleOnSendToken)
  context.on('progress', handleOnProgress)

  return (
    <Column fullWidth>
      {loading && <img className={classes.image} src={background} />}
      <UnityContainer />
      {/* <Route path="/test" element={<Text text="test" />} /> */}
    </Column>
  )
}
