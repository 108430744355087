import { SignInBody } from '../../services'

export enum Storage {
  ACCESS_TOKEN = 'access'
}

export type UserValue = {
  token: string
  isLoggedIn: boolean
  onSignIn?: (credentials: SignInBody) => Promise<void>
}
