import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    height: '100vh',
    width: '100vw'
  },
  container: {
    width: 450,
    maxWidth: '100%',
    padding: 24,
    border: `1px solid ${theme.colors.lightGrey}`
  },
  input: {
    marginTop: 16
  },
  submit: {
    marginTop: 24
  }
}))
