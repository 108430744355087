import { useEffect, useState } from 'react'

import { RefreshBody, SignInBody } from '../../services'

import { useApi } from '../api'

import { Storage, UserValue } from './user.types'

export const useUser = (): UserValue => {
  const { api } = useApi()
  const [token, changeToken] = useState('')
  const [isLoggedIn, changeIsLoggedIn] = useState(false)

  const saveTokenToLocalStorage = (tokenValue: string) => {
    localStorage.setItem(Storage.ACCESS_TOKEN, tokenValue)
  }

  const handleOnLogin = (tokenValue: string) => {
    changeToken(tokenValue)
    saveTokenToLocalStorage(tokenValue)
    changeIsLoggedIn(true)
  }

  const handleOnRefresh = async (value: RefreshBody) => {
    const response = await api.refresh(value)

    if (response.kind === 'ok') {
      handleOnLogin(response.results.token)
    }
  }

  useEffect(() => {
    const tokenLocalStorage = localStorage.getItem(Storage.ACCESS_TOKEN)

    if (tokenLocalStorage) {
      handleOnRefresh({ token: tokenLocalStorage })
    }
  }, [])

  const handleOnSignIn = async (credentials: SignInBody) => {
    const response = await api.signIn(credentials)

    if (response.kind === 'ok') {
      handleOnLogin(response.results.token)
    }
  }

  return {
    token,
    isLoggedIn,
    onSignIn: handleOnSignIn
  }
}
