import React, { createContext, FC, useContext } from 'react'

import { config } from '../../config'
import { create, Api } from '../../services'
import { StoreProvider } from '../store'

import { ApiProviderProps, ApiValue } from './api.types'

const api: Api = create(config.apiURL)

export const ApiContext = createContext<ApiProviderProps>({
  api
})

export const useApi = () => useContext<ApiValue>(ApiContext)

export const ApiProvider: FC = ({ children }) => {
  const value = { api }

  return (
    <ApiContext.Provider value={value}>
      <StoreProvider>{children}</StoreProvider>
    </ApiContext.Provider>
  )
}
