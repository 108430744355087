import { createUseStyles } from 'react-jss'

import { ColumnProps } from './column.types'

export const useStyle = createUseStyles({
  column: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: (props: ColumnProps) => props.alignItems,
    justifyContent: (props: ColumnProps) => props.justifyContent,
    height: (props: ColumnProps) => props.fullHeight && '100%',
    width: (props: ColumnProps) => props.fullWidth && '100%'
  }
})
