import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useTheme } from 'react-jss'
import he from 'he'

import { LightTheme } from '../../theme'

import { useStyle } from './text.styles'
import { TextProps } from './text.types'
import { presetComponents } from './text.presets'

const removeBackSlash = (str: string) => {
  return str.replace(/\\/g, '')
}

export const Text: FC<TextProps> = ({
  className = '',
  description,
  text = '',
  tx,
  values,
  preset = 'text',
  innerHtml,
  color = 'black',
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ color, text, theme })
  const Component = presetComponents[preset]

  if (!text) {
    return <></>
  }

  if (!tx) {
    return (
      <Component className={`${className} ${classes[preset]}`} {...props}>
        {removeBackSlash(he.decode(text))}
      </Component>
    )
  }

  return (
    <Component className={`${className} ${classes[preset]}`} {...props}>
      <FormattedMessage
        id={tx || text}
        description={description}
        defaultMessage={text}
        values={values}
      />
    </Component>
  )
}
