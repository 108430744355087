import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  unity: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh'
  }
})
