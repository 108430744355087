export const lightTheme = {
  colors: {
    main: '#00132A',
    second: '#FF4C00',
    white: '#FFFFFF',
    lightGrey: '#F1F1F1',
    hyundai: '#012C60',
    black: 'rgba(0, 0, 0, 0.87)',
    title: '#88969E',
    gradient: 'linear-gradient(221.86deg, #012C60 14.71%, #01AAD2 85.82%)',
    red: '#CF0019',
    icon: '#AFE4E0',
    lightBlue: '#00AEDC',
    pink: '#F6F3F2',
    background: '#C4C4C4',
    green: '#159903',
    transparent: 'transparent'
  },
  fonts: {
    main: ['Montserrat', 'sans-serif'],
    defaultSize: 16
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 70,
      lineHeight: '87px',
      margin: 0
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: 36,
      lineHeight: '43px',
      margin: 0
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 34,
      lineHeight: '41px',
      margin: 0
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '28px',
      margin: 0
    },
    h5: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '22px',
      margin: 0
    },
    h6: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '20px',
      margin: 0
    },
    text: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '14.83px'
    }
  },
  breakpoints: {
    xs: {
      flex: 1,
      minWidth: 244,
      maxWidth: 244
    },
    sm: {
      flex: 2,
      minWidth: 244,
      maxWidth: 328
    },
    md: {
      flex: 3,
      minWidth: 244,
      maxWidth: 620
    },
    lg: {
      minWidth: 244,
      maxWidth: 980,
      flex: 4
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  spacing: {
    unit: 16
  },
  content: {
    maxWidth: 1216,
    margin: 'auto'
  },
  boxShadow:
    '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
  link: {
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '.25em 1em'
  }
}

export type Color = keyof typeof lightTheme.colors

type LightType = typeof lightTheme

export interface LightTheme extends LightType {}
