import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTheme } from 'react-jss'
import { Color, LightTheme } from '../../theme'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './text-field.styles'
import { TextFieldProps } from './text-field.types'

export const TextField: FC<TextFieldProps> = ({
  preset = 'border',
  className = '',
  inputClassName = '',
  isSecure,
  label,
  labelTx,
  type: defaultType = 'text',
  defaultValue = '',
  leftIcon,
  rightElement,
  textInputPreset,
  value: outerValue,
  onChange,
  onRightElementClick,
  error,
  errorTx,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, changeValue] = useState(defaultValue)
  const [focus, changeFocus] = useState(false)
  const [visible, changeVisible] = useState(!isSecure)

  const CurrentValue = useMemo(() => {
    if (typeof outerValue !== 'undefined') {
      return outerValue
    }

    return value
  }, [value, outerValue])

  const theme = useTheme<LightTheme>()
  const classes = useStyle({
    error,
    focus,
    leftIcon,
    password: isSecure && !visible,
    preset,
    textInputPreset,
    theme,
    value: CurrentValue
  })

  const activeLabelColor: Color = !focus && !CurrentValue ? 'title' : 'main'
  const type = isSecure && !visible ? 'password' : defaultType
  const secureIcon = visible ? ICONS.removeRedEye : ICONS.visibilityOff

  const handleOnVisible = (
    event: MouseEvent<SVGElement, globalThis.MouseEvent>
  ) => {
    changeVisible(!visible)
    event.preventDefault()
    event.stopPropagation()
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = event.target
    changeValue(nextValue)

    if (onChange) {
      onChange(event)
    }
  }

  const handleOnFocus = (state: boolean) => () => {
    changeFocus(state)
  }

  const handleOnClickContainer = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <Column fullWidth className={className}>
      <Row
        className={classes.container}
        justifyContent="space-between"
        onClick={handleOnClickContainer}
      >
        {leftIcon && (
          <Icon color="title" src={leftIcon} height={24} width={24} />
        )}
        <Column
          fullWidth
          className={classes.inputContainer}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            className={classes.label}
            color={activeLabelColor}
            preset="text"
            text={label}
            tx={labelTx}
          />
          <input
            {...props}
            className={`${inputClassName} ${classes.input}`}
            value={CurrentValue}
            type={type}
            ref={inputRef}
            onFocus={handleOnFocus(true)}
            onBlur={handleOnFocus(false)}
            onChange={handleOnChange}
          />
        </Column>
        {isSecure && CurrentValue && (
          <Icon
            className={classes.icon}
            color="black"
            src={secureIcon}
            onClick={handleOnVisible}
          />
        )}
        {rightElement && (
          <Row className={classes.icon} onClick={onRightElementClick}>
            {rightElement}
          </Row>
        )}
      </Row>
      {typeof error !== 'undefined' && (
        <Column fullWidth>
          <Text
            className={classes.errorText}
            text={error}
            tx={errorTx}
            color="red"
            preset="text"
          />
        </Column>
      )}
    </Column>
  )
}
