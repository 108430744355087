import { ApiResponse } from 'apisauce'
import { GeneralApiProblem } from './api-problem'

export enum AuthPathes {
  SIGN_IN = '/user/token-access',
  REFRESH = '/user/token-refresh'
}

export type SignInBody = {
  email: string
  password: string
}

export type RefreshBody = {
  token: string
}

export type SignInResponse = {
  token: string
}

export type RefreshResponse = {
  token: string
}

export type SignInResults =
  | { kind?: string; results: SignInResponse }
  | GeneralApiProblem

export type RefreshResults =
  | { kind?: string; results: RefreshResponse }
  | GeneralApiProblem

export type GetDealersResults =
  | { kind?: string; dealers: any[] }
  | GeneralApiProblem

export type Api = {
  getRoot: () => Promise<ApiResponse<unknown, unknown>>
  setHeader: (key: string, prop: any) => void
  setToken: (token: string) => void
  refresh: (credentials: RefreshBody) => Promise<RefreshResults>
  signIn: (credentials: SignInBody) => Promise<SignInResults>
}
