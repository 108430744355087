import React, { createContext, FC, useContext } from 'react'
import { UnityContext } from 'react-unity-webgl'

import { config } from '../../config'

const frameworkUrl = `${config.publicURL}${config.buildFolder}/Build.framework.js`
const loaderUrl = `${config.publicURL}${config.buildFolder}/Build.loader.js`
const dataUrl = `${config.publicURL}${config.buildFolder}/Build.data`
const codeUrl = `${config.publicURL}${config.buildFolder}/Build.wasm`
// TODO: Path to assets
// const streamingAssetsUrl = `${config.publicURL}/StreamingAssets/`;

export const unityContext = new UnityContext({
  frameworkUrl,
  loaderUrl,
  dataUrl,
  codeUrl
  // TODO: Path to assets
  // streamingAssetsUrl,
})

export type UnityContextProviderProps = {
  context: UnityContext
}

export const UnityContextProvider = createContext<UnityContextProviderProps>({
  context: unityContext
})

export const useUnity = () =>
  useContext<UnityContextProviderProps>(UnityContextProvider)

export const UnityProvider: FC = ({ children }) => {
  const value = {
    context: unityContext
  }

  return (
    <UnityContextProvider.Provider value={value}>
      {children}
    </UnityContextProvider.Provider>
  )
}
