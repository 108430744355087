import React, { createContext, FC, useContext } from 'react'

import { useUser } from '../user'

import { StoreProviderProps, StoreValue } from './store.types'

export const StoreContext = createContext<StoreProviderProps>({
  user: {
    token: '',
    isLoggedIn: false
  }
})

export const StoreProvider: FC = ({ children }) => {
  const user = useUser()
  const value = { user }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export const useStore = () => useContext<StoreValue>(StoreContext)
