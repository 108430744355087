import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { TextFieldStyleProps } from './text-field.types'

export const presetsContainer =
  (theme: LightTheme) => (focus?: boolean, error?: string) => ({
    border: focus
      ? {
          border: `2px solid ${theme.colors.main}`,
          borderRadius: 4,
          cursor: 'text',
          padding: '3px 24px'
        }
      : {
          border: `1px solid ${theme.colors[error ? 'red' : 'title']}`,
          borderRadius: 4,
          cursor: 'text',
          padding: '4px 25px'
        },
    'bottom-border': focus
      ? {
          borderBottom: `2px solid ${theme.colors.main}`,
          padding: '0px 4px',
          cursor: 'text'
        }
      : {
          borderBottom: `2px solid ${theme.colors.black}`,
          cursor: 'text',
          padding: '0px 4px'
        },
    main: {
      borderRadius: 4,
      cursor: 'text',
      padding: '4px 24px'
    }
  })

export const useStyle = createUseStyles((theme: LightTheme) => {
  const containerPresets = presetsContainer(theme)

  return {
    container: ({ preset = 'main', focus, error }: TextFieldStyleProps) => ({
      background: theme.colors.white,
      ...containerPresets(focus, error)[preset]
    }),
    icon: {
      cursor: 'pointer',
      marginLeft: 8
    },
    input: ({ textInputPreset = 'text', password }: TextFieldStyleProps) => ({
      ...theme.typography[textInputPreset],
      background: theme.colors.transparent,
      color: theme.colors.black,
      letterSpacing: password ? '8px' : 'normal',
      marginTop: 4,
      padding: 0,
      border: 'none',
      outline: 'none',
      width: '100%'
    }),
    inputContainer: {
      marginLeft: ({ leftIcon }: TextFieldStyleProps) => leftIcon && 8
    },
    label: {
      transform: ({ focus, value }: TextFieldStyleProps) =>
        !focus && !value && 'translate(0px, 13px) scale(1.15)',
      transformOrigin: ({ focus, value }: TextFieldStyleProps) =>
        !focus && !value && '0%',
      transition:
        'transform-origin 0.25s ease-in-out, transform 0.25s ease-in-out'
    },
    errorText: {
      width: 'calc(100% - 48px)',
      height: 14
    }
  }
})
