import { LightTheme } from '../../theme'
import { ButtonStyleProps } from './button.types'

export const buttonPresets = (theme: LightTheme) => ({
  border: {
    padding: '4px 8px',
    borderRadius: 4,
    backgroundColor: theme.colors.transparent,
    border: `2px solid ${theme.colors.hyundai}`,
    '&:hover': {
      borderColor: theme.colors.lightBlue
    },
    '&:hover > span': {
      color: theme.colors.lightBlue
    }
  },
  main: {
    backgroundColor: ({ color = 'hyundai' }: ButtonStyleProps) =>
      theme.colors[color],
    borderRadius: 4,
    padding: '4px 8px',
    '&:hover': {
      background: theme.colors.lightBlue,
      borderColor: theme.colors.lightBlue
    }
  }
})
