import decamelize from './decamelize'

export const decamelizeObject = (object: any, separator = '_') => {
  if (!object) return {}
  return Object.entries(object).reduce(
    (acc, item) => ({ ...acc, [decamelize(item[0], separator)]: item[1] }),
    {}
  )
}

export default decamelizeObject
