// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import camelcaseKeys from 'camelcase-keys'

import { decamelizeObject } from '../utils'

import { getGeneralApiProblem } from './api-problem'
import * as Types from './api.types'

export const camelcaseOptions = { deep: true }

// our "constructor"
export const create = (
  baseURL = 'https://ringo-editor-webadmin.consultinar.com/api'
) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST',
      'Access-Control-Allow-Headers': 'X-Requested-With'
    },
    // 10 second timeout...
    timeout: 10000
  })
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const getRoot = () => api.get('')

  const setHeader = (key: string, prop: any) => {
    const headers = { ...api.headers, [key]: prop }
    api.setHeaders(headers)
  }

  const setHeaders = (headers: Record<string, string>) => {
    const { setHeaders: set, headers: prevHeaders } = api
    set({
      ...prevHeaders,
      ...headers
    })
  }

  const setAuthorizationHeader = (token: string) => {
    const headers: Record<string, any> = {
      Authorization: token ? `Bearer ${token}` : undefined
    }
    setHeaders(headers)
  }

  const signIn = async (
    credentials: Types.SignInBody
  ): Promise<Types.SignInResults> => {
    const response = await api.post<Types.SignInResponse>(
      Types.AuthPathes.SIGN_IN,
      decamelizeObject(credentials)
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      if (response.data) {
        const rawResponse = camelcaseKeys<Types.SignInResponse>(
          response.data,
          camelcaseOptions
        )
        setAuthorizationHeader(rawResponse.token)

        return { kind: 'ok', results: rawResponse }
      }
    } catch {
      return { kind: 'bad-data' }
    }

    return { kind: 'bad-data' }
  }

  const refresh = async (
    body: Types.RefreshBody
  ): Promise<Types.RefreshResults> => {
    const response = await api.post<Types.RefreshResponse>(
      Types.AuthPathes.REFRESH,
      decamelizeObject(body)
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      if (response.data) {
        const rawResponse = camelcaseKeys<Types.RefreshResponse>(
          response.data,
          camelcaseOptions
        )
        setAuthorizationHeader(rawResponse.token)

        return { kind: 'ok', results: rawResponse }
      }
    } catch {
      return { kind: 'bad-data' }
    }

    return { kind: 'bad-data' }
  }

  const setToken = (token: string) => {
    if (token) {
      api.setHeader('Authorization', `Bearer ${token}`)
    } else {
      api.setHeader('Authorization', '')
    }
  }

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    getRoot,
    setHeader,
    setToken,
    refresh,
    signIn
  }
}
