import React, { ChangeEvent, useState } from 'react'

import { Button, Column, Text, TextField } from '../../components'
import { useStore } from '../../providers'
import { SignInBody } from '../../services'

import { useStyle } from './sign-in.styles'

const defaultUserState = {
  email: '',
  password: ''
}

export const SignIn = () => {
  const { user: userStore } = useStore()
  const [user, changeUser] = useState<SignInBody>(defaultUserState)
  const classes = useStyle()

  const isDisabled = !user.email || !user.password

  const handleOnChange =
    (prop: keyof SignInBody) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeUser({
        ...user,
        [prop]: value
      })
    }

  const handleOnClick = () => {
    if (userStore.onSignIn) {
      userStore.onSignIn(user)
    }
  }

  return (
    <Column
      alignItems="center"
      className={classes.content}
      justifyContent="center"
    >
      <Column className={classes.container}>
        <Text color="title" preset="h6" text="Please sign in to continue." />

        <TextField
          className={classes.input}
          label="E-mail"
          preset="border"
          type="text"
          onChange={handleOnChange('email')}
        />
        <TextField
          isSecure
          className={classes.input}
          label="Password"
          preset="border"
          type="text"
          onChange={handleOnChange('password')}
        />
        <Button
          className={classes.submit}
          disabled={isDisabled}
          preset="main"
          text="SIGN IN"
          textColor="white"
          textPreset="h5"
          onClick={handleOnClick}
        />
      </Column>
    </Column>
  )
}
