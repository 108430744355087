import React, { FC } from 'react'
import Unity from 'react-unity-webgl'

import { useUnity } from '../providers'

import { useStyle } from './unity.styles'

export type LoadEvent = {}

export type UnityContainerProps = {}

export const UnityContainer: FC<UnityContainerProps> = () => {
  const { context: unityContext } = useUnity()
  const classes = useStyle()

  return <Unity className={classes.unity} unityContext={unityContext} />
}
