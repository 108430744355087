import React, { FC } from 'react'
import SVG from 'react-inlinesvg'
import { useTheme } from 'react-jss'

import { Color, LightTheme } from '../../theme'
import arrowDown from './arrow_down.svg'
import arrowTop from './keyboard_arrow_top.svg'
import arrowRight from './arrow_right.svg'
import arrowLeft from './arrow_left.svg'
import arrowBottom from './keyboard_arrow_bottom.svg'
import attention from './attention.svg'
import chatCustomer from './chat_customer.svg'
import chatVendor from './chat_vendor.svg'
import checkbox from './check_box.svg'
import chevronLeft from './chevron-left.svg'
import chevronRight from './chevron-right.svg'
import close from './close.svg'
import collections from './collections.svg'
import dateRange from './date_range.svg'
import doneAll from './done_all.svg'
import facebook from './facebook.svg'
import female from './female.svg'
import filledArrow from './filled-arrow.svg'
import { IconProps } from './icon.types'
import insertChart from './insert_chart.svg'
import instagram from './instagram.svg'
import keyboardArrowLeft from './keyboard_arrow_left.svg'
import keyboardArrowRight from './keyboard_arrow_right.svg'
import libraryBooks from './library_books.svg'
import linkedin from './linkedin.svg'
import male from './male.svg'
import newReleases from './new_releases.svg'
import playlistAddCheck from './playlist_add_check.svg'
import removeRedEye from './remove-red-eye.svg'
import search from './search.svg'
import supervisorAccount from './supervisor_account.svg'
import trendingFlat from './trending_flat.svg'
import twitter from './twitter.svg'
import localOffer from './local_offer.svg'
import uk from './uk.svg'
import visibilityOff from './visibility-off.svg'
import workOutline from './work_outline.svg'
import attachMoney from './attach_money.svg'
import trash from './delete.svg'
import trendingFlatTop from './trending_flat_top.svg'
import infinity from './infinity.svg'
import phonelink from './phonelink.svg'
import localATM from './local-atm.svg'
import settings from './settings.svg'
import edit from './edit.svg'
import deleteIcon from './delete-icon.svg'
import payment from './payment.svg'
import businessCenter from './business-center.svg'
import steeringWheel from './steering-wheel.svg'
import paypal from './paypal.svg'
import filterList from './filter_list.svg'
import grain from './grain.svg'
import bill from './bill.svg'
import percentage from './percentage.svg'
import feedback from './feedback.svg'
import starBorder from './star-border.svg'
import starFilled from './star-filled.svg'

export const Icon: FC<IconProps> = ({ src, color, ...props }) => {
  const theme: LightTheme = useTheme()

  const handlePreProcessor = (nextColor?: Color) => (code: string) => {
    if (!nextColor) {
      return code
    }

    const colorFill = theme.colors[nextColor]

    return code.replace(/fill=".*?"/g, `fill="${colorFill}"`)
  }

  return (
    <SVG
      {...props}
      cacheRequests
      uniquifyIDs
      key={`active_false_${src}`}
      preProcessor={handlePreProcessor(color)}
      src={src}
    />
  )
}

export const ICONS = {
  arrowBottom,
  arrowDown,
  arrowTop,
  attachMoney,
  attention,
  chatCustomer,
  chatVendor,
  checkbox,
  chevronLeft,
  chevronRight,
  close,
  collections,
  dateRange,
  doneAll,
  trash,
  facebook,
  female,
  filledArrow,
  infinity,
  insertChart,
  instagram,
  keyboardArrowLeft,
  keyboardArrowRight,
  libraryBooks,
  linkedin,
  localATM,
  male,
  newReleases,
  phonelink,
  playlistAddCheck,
  removeRedEye,
  search,
  supervisorAccount,
  trendingFlat,
  twitter,
  uk,
  visibilityOff,
  workOutline,
  trendingFlatTop,
  localOffer,
  settings,
  edit,
  deleteIcon,
  businessCenter,
  payment,
  steeringWheel,
  paypal,
  filterList,
  arrowRight,
  arrowLeft,
  grain,
  bill,
  percentage,
  feedback,
  starFilled,
  starBorder
}
