import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { Home, SignIn } from '../../pages'

import { MainRoutes } from './main.types'

export const Main = () => {
  return (
    <Routes>
      <Route path={MainRoutes.HOME} element={<Home />} />
      <Route path={MainRoutes.SIGN_IN} element={<SignIn />} />
    </Routes>
  )
}
