import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { TextPropsWithColor } from './text.types'

const colorByProps =
  (theme: LightTheme, defaultColor?: string) => (props: TextPropsWithColor) =>
    theme.colors[props.color] || defaultColor || theme.colors.black

export const useStyle = createUseStyles((theme: LightTheme) => ({
  h1: {
    ...theme.typography.h1,
    color: colorByProps(theme)
  },
  h2: {
    ...theme.typography.h2,
    color: colorByProps(theme)
  },
  h3: {
    ...theme.typography.h3,
    color: colorByProps(theme)
  },
  h4: {
    ...theme.typography.h4,
    color: colorByProps(theme)
  },
  h5: {
    ...theme.typography.h5,
    color: colorByProps(theme)
  },
  h6: {
    ...theme.typography.h6,
    color: colorByProps(theme)
  },
  text: {
    ...theme.typography.text,
    color: colorByProps(theme)
  }
}))
