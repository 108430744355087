import React from 'react'
import { ThemeProvider } from 'react-jss'
import { BrowserRouter as Router } from 'react-router-dom'

import './App.css'

import { lightTheme } from './theme'
import { StoreProvider, UnityProvider } from './providers'
import { Main } from './routes'

function App() {
  return (
    <StoreProvider>
      <ThemeProvider theme={lightTheme}>
        <Router>
          <UnityProvider>
            <Main />
          </UnityProvider>
        </Router>
      </ThemeProvider>
    </StoreProvider>
  )
}

export default App
