import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'

import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './button.style'
import { ButtonProps } from './button.types'

export const Button: FC<ButtonProps> = ({
  color,
  border,
  className = '',
  disabled,
  preset = 'border',
  textColor,
  textPreset,
  text,
  tx,
  onClick
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle({ color, border, disabled, theme })
  const presetClass = classes[preset]

  return (
    <Row
      className={`${className} ${classes.container} ${presetClass}`}
      onClick={onClick}
    >
      <Text
        className={classes.text}
        color={textColor}
        preset={textPreset}
        text={text}
        tx={tx}
      />
    </Row>
  )
}
